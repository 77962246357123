<template>
  <div>
    <h2 class="text-2xl font-bold">Card Transactions</h2>

    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
      <div class="col-span-1">
        <div class="relative">          
          <search-form v-model="searchQuery" @submit="event => $refs.table.loadAjaxData()" />
        </div>
      </div>
    </div>

    <component
      :is="tab"
      :searchQuery="searchQuery"
      :search-field="$refs.searchField"
    />
  </div>
</template>

<script>
export default {
  components: {
    transaction: () => import('./Transactions'),
    sent: () => import('./Sent')
  },
  data() {
    return {
      searchQuery: "",
      tab: "transaction",
      tabs: [
        {name: 'transaction', title: 'Transactions'},
        {name: 'sent', title: 'Sent Transactions'},
      ]
    };
  },
  watch: {
    tab(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router.replace({ ...this.$route, query: { tab } });
      }
    }
  },
  mounted() {
    if (this.$route.query.tab?.match(/transaction/)) {
      this.tab = this.$route.query.tab;
    }
  }
};
</script>
